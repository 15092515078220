<template>
    <v-dialog v-model="openDialog" persistent width="700" >
        <v-card>
            <v-toolbar flat class="bg-gradient" height="64">
                <div style="display:flex; width:100%; height:64px; align-items:center;">
                    <v-btn x-large depressed color="transparent" style="position:absolute;" class="ml-2" dark @click.native="$emit('cancel')" ><v-icon small class="mr-2">$prev</v-icon> {{ $t("back") }}</v-btn>
                    <v-toolbar-title class="d-flex align-center justify-center toolbarTitle">{{ $t('notify_coachs') }}</v-toolbar-title>
                </div> 
            </v-toolbar>
            <v-card-text>
                <v-alert border='left' :icon="'$warning_s'" width="100%" type="error" text class="mb-0 mt-5">
                    <p style="padding-bottom: 0;" class="error--text mb-0" v-html="$t('warningactionirreversible')"></p>
                </v-alert>
                <p class="black--text mt-4"><b>{{$t('sendNotificationToCoachText')}} :</b></p>
                <v-card outlined v-for="(rp, index) in rps" :key="rp.id" class="mb-2" style="border-color:#161459 !important;" rounded="lg">
                    <v-card-title class="d-flex justify-space-between py-3">
                        <div>
                            <div class="dateElement mb-1" v-if="rp.config.date_start">
                                {{parseFullDateToFr(rp.config.date_start)}}
                                <span v-if="rp.config.date_end"> - {{parseFullDateToFr(rp.config.date_end)}}</span>
                            </div>
                            <h5 class="pb-0">{{ rp.config.name }}</h5>
                        </div>
                        <div v-if="rp.config.hasOwnProperty('coach_selected')">
                            <v-tooltip v-for="(coach, index) in rp.config.coach_selected" :key="'coach_'+coach.value+'_'+rp.id" top>
                                <template v-slot:activator="{ on, attrs }" class="ml-2">
                                    <v-avatar size="50" v-bind="attrs" v-on="on">
                                        <v-img :src="api+'/user/'+coach.value+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true&ts='+rand"></v-img>
                                    </v-avatar>
                                </template>
                                <span>{{ coach.text }}</span>
                            </v-tooltip>
                        </div>
                        <div v-if="rp.config.hasOwnProperty('coach_selection')">
                            <v-tooltip v-for="(coach, index) in rp.config.coach_selection" :key="'coach_'+coach.value+'_'+rp.id" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-avatar size="50" v-bind="attrs" v-on="on" class="ml-2">
                                        <v-img :src="api+'/user/'+coach.value+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true&ts='+rand"></v-img>
                                    </v-avatar>
                                </template>
                                <span>{{ coach.text }}</span>
                            </v-tooltip>
                        </div>
                    </v-card-title>
                   
                </v-card>
                <div class="mt-4 d-flex justify-end">
                    <v-btn depressed  @click.native="$emit('cancel')" class="mr-4">{{$t('cancel')}}</v-btn>
                    <v-btn depressed :loading="loading" color="primary" @click="assignProgramAndNotify">{{$t('continue')}}</v-btn>
                </div>
            </v-card-text>
            
        </v-card>
    </v-dialog>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'
import GenericDataService from '@/services/GenericDataService'
export default {
    name:"notifyCoachDialog",
    props:["rps", "openDialog", "operationId"],
    mixins: [dateManipulation],
    data() {
        return {
            api:this.$store.state.auth.apiUrl,
            rand:0,
            loading: false
        }
    },
    methods: {
        assignProgramAndNotify(){
            this.loading = true
            GenericDataService.postData("/operation/"+this.operationId+"/assignProgramAndNotify", this.rps).then((response) => {
                this.loading = false
                this.$emit('goNextAfter');
            }).catch((error) => {
                this.loading = false
            })
            
        }
    },
}
</script>
<style lang="scss">
    
</style>