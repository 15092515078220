<template>
    <v-card rounded="lg">
        <v-card-title>
            <header class="d-flex px-3" style="align-items: center; justify-content: space-between ; width:100%;;">
                <h2 style="text-transform: none; font-size:22px;">{{$t('Operationretroplanning')}}</h2>
                <v-spacer></v-spacer>
                <v-menu v-if="$func.hasRight('retroplanning/add')" offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" :disabled="operation.status == 'CLOSED'" v-on="on" @click.stop="" depressed :class="operation.status != 'CLOSED' ? 'bg-gradient' : ''">
                            <v-icon left dark small>$plus_l</v-icon>{{ $t('add') }}
                        </v-btn>
                    </template>
                    <v-list v-for="(subTypes, type) in actions" :key="type">
                        <div class="pa-2 pl-4 listTitle">{{ $t(type === "CAMP_CALL" ? "prospection" : type) }}</div>
                        <v-list-item v-for="(subType) in subTypes" :key="subType" link dense @click="openForm(type, subType)">
                            <v-list-item-title>{{ $t(subType) }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </header>
        </v-card-title>
        <v-card-text>
            <div class="retroplanning px-3 pb-3">
                <v-row dense v-if="retroplanning.length > 0">
                    <v-col v-for="(retro) in retroplanning" :key="retro.id" md="12" >

                        <!-- Card classique RP -->
                        <v-card class="rpCard no-shadow" depressed rounded="lg" :ripple="false"
                                v-if="(retro.type !== 'operationStart' && retro.type !== 'operationEnd')" @[canGoTo(retro)].stop="getLink(retro.actions.actions['SHOW'], retro)">
                            <!--div class="rpNumber">{{ index+1 }}</div-->
                            <v-btn class="rpIcon no-shadow" depressed fab small color="lightgrey">
                                <v-icon v-if="retro.settings" small color="primary">{{retro.settings.icon}}</v-icon>
                            </v-btn>
                            <div class="pl-4">
                                <p class="primary--text mb-1" v-if="retro && retro.config && retro.config.date_start">
                                    {{parseFullDateToFr(retro.config.date_start)}}
                                        <span v-if="retro.config.date_end"> - {{parseFullDateToFr(retro.config.date_end)}}</span>
                                </p>
                                <span class="rpName">{{retro.config.name}}</span>
                            </div>

                            <v-spacer></v-spacer>
                            <div class="ml-3" v-if="retro && (retro.type == 'CAMP_OUT_P' || (retro.type == 'CAMP_CALL' && retro.sousType == 'campSMSconversational')) && (!retro.config.template || (retro.sousType === 'campMAILINGout' && !retro.config.template_envelope))">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="error" v-bind="attrs" v-on="on">$file_d</v-icon> 
                                    </template>
                                    <span>{{ $t('noTemplateSelected') }} </span>
                                </v-tooltip>
                            </div>
                            <div class="ml-3" v-else-if="retro && (retro.type== 'CAMP_OUT_P' || (retro.type == 'CAMP_CALL' && retro.sousType == 'campSMSconversational')) && (retro.config.template && (retro.sousType !== 'campMAILINGout' || retro.config.template_envelope))">
                                <v-tooltip left content-class="white--tooltip">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="info" v-bind="attrs" v-on="on">$file_d</v-icon> 
                                    </template>
                                    <span>
                                        <span><b>{{ $t('template_s') }} : </b></span>
                                        <ul>
                                            <li v-if="retro.config.template && retro.config.template.name">{{ retro.config.template.name }}</li>
                                            <li v-if="retro.config.template_envelope && retro.config.template_envelope.name">{{ retro.config.template_envelope.name }}</li>
                                        </ul>
                                    </span>
                                </v-tooltip>
                            </div>
                            <div class="ml-3" v-if="retro && (retro.type== 'CAMP_OUT_P' || retro.type== 'CAMP_CALL') && (!retro.config.contact_list || retro.config.contact_list.length ==0)">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="error" v-bind="attrs" v-on="on">$pollpeople_d</v-icon> 
                                    </template>
                                    <span>{{$t('noContactListSelected')}} </span>
                                </v-tooltip>
                            </div>
                            <div class="ml-3" v-else-if="retro && (retro.type== 'CAMP_OUT_P' || retro.type== 'CAMP_CALL') && (retro.config.contact_list && retro.config.contact_list.length > 0)">
                                <v-tooltip left content-class="white--tooltip">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="info" v-bind="attrs" v-on="on">$pollpeople_d</v-icon> 
                                    </template>
                                    <span>
                                        <span v-if="retro.status !== 'COMPLETED'"><b>{{ $t('single_eligible_recipients') }} : {{ retro.config.contact_number }}</b></span>
                                        <span v-else><b>{{ $t('contact_list_s') }} : </b></span>
                                        <ul>
                                            <li v-for="(contact_list, index) in retro.config.contact_list_hydrat">{{ contact_list.name }} <span v-if="retro.status !== 'COMPLETED'">({{ contact_list.contact_count }})</span></li>
                                        </ul>
                                    </span>
                                </v-tooltip>
                            </div>
                            <div class="ml-3" v-if="retro.config && !retro.config.main_contact && (retro.config.type === 'MISSION' || (retro.config.type === 'CAMP_CALL' && retro.config.sousType === 'campCALL' && retro.config.prestataire_type === 'CALL'))" >
                                <v-tooltip left content-class="white--tooltip">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="warning" v-bind="attrs" v-on="on">$usercross_d</v-icon> 
                                    </template>
                                    <span>{{$t('mainContactEmpty')}} </span>    
                                </v-tooltip>
                            </div>
                            <div class="ml-3" v-if="retro.config && ['missionCOACH', 'missionCOACHtel'].includes(retro.config.sousType)">
                                <div v-if="!retro.provider_users_confirmed.length">
                                    <v-tooltip left content-class="white--tooltip">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="error" v-bind="attrs" v-on="on">$user_hard_hat_d</v-icon> 
                                        </template>
                                        <span>{{$t('coach_sEmpty')}} </span>    
                                    </v-tooltip>
                                </div>
                                <div class="ml-3" v-else>
                                    <v-tooltip left content-class="white--tooltip">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="info" v-bind="attrs" v-on="on">$user_hard_hat_d</v-icon> 
                                        </template>
                                        <span>
                                            <b>{{$t('coach_s')}} :</b>
                                            {{ retro.provider_users_confirmed.map(e => e.fullname).join(", ") }}   
                                        </span>    
                                    </v-tooltip>
                                </div>
                            </div>
                            <div class="ml-3" v-if="retro && retro.config && retro.config.prestataire && retro.config.prestataire.showLimitDateProvider" >
                                <v-tooltip left content-class="white--tooltip">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="warning" v-bind="attrs" v-on="on">$warning_d</v-icon> 
                                    </template>
                                    <span>{{retro.config.prestataire.showLimitDateProvider}} {{$t('days_delay_for_provider')}} </span>
                                </v-tooltip>
                            </div>
                            <div v-if="retro && retro.config && retro.config.hasOwnProperty('managing_type') && retro.config.managing_type == 'user' && !retro.config.hasOwnProperty('managing_user_id')">
                                <v-menu open-on-hover left offset-y origin="center center" transition="scale-transition" elevation="12">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="error" v-bind="attrs" v-on="on">$user_plus_d</v-icon>
                                    </template>

                                    <div class="popover pa-2" style="background-color:#FFF;">
                                    {{$t('needToSelectUser')}}
                                    </div>
                                </v-menu>
                            </div>
                            
                            <div v-if="retro && retro.config && retro.config.multipleStatus" class="d-flex flex-column">
                                <v-chip :class="retro.config.statusByEntity.length > 1 ? 'ml-3 mb-1' : 'ml-3'" small dark v-for="(status,index) in retro.config.statusByEntity" :key="index" :color="$func.getChipColorByStatus(index)">
                                    {{ $t(index) }} : {{ (status.join(', ')) }}
                                </v-chip>
                            </div>
                            <v-chip class="ml-3" small dark v-else-if="retro.status" :color="$func.getChipColorByStatus(retro.status)">
                                {{ $t(retro.status) }}
                            </v-chip>

                            <div class="ml-3">
                                <v-menu offset-y left v-if="retro.actions && retro.actions.retroplanning">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" @click.stop=""  class="square float-right" depressed text>
                                            <v-icon >$dots_l</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(action, index) in retro.actions.retroplanning" :disabled="action.disabled || ((action.hasOwnProperty('checkIf') ? retro.config[action.checkIf.field] == action.checkIf.value && !retro.config[action.check] : action.check && !retro.config[action.check]))" :key="index" @click.stop="getLink(action, retro)" link dense>
                                            <v-list-item-title>{{ $t(action.title) }}</v-list-item-title>
                                        </v-list-item>
                                        <!-- <v-list-item  link dense v-if="((retro.type== 'CAMP_OUT_P' && retro.config.template && retro.config.template.id))" :href=" api +'/retroplanning/'+retro.id+'/send?export=true&token=' + $store.state.auth.idToken" target="_blank"> -->
                                        <v-list-item link dense @click="getExportUrl(retro.id)" v-if="retro.type != 'OTHER' && retro.sousType != 'campSMSconversational' && $func.hasRight('export/export')" target="_blank">
                                            <v-list-item-title>{{ $t('export') }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </v-card>


                        <!-- Card Start -->
                        <v-card depressed rounded="lg" class="operation no-shadow" v-else>
                            <v-img
                                :src= "retro.type === 'operationStart' ? publicPath+'img/fusee.png' : publicPath+'img/drapeau.png'"
                                height="50"
                                max-width="80"
                                contain
                            ></v-img>
                            <div class="ml-4">
                                <div class="rpName">{{ $t(retro.config.name) }}</div>
                                {{ parseFullDateToFr(retro.config.date_start) }}
                            </div>
                        </v-card>

                    </v-col>
                </v-row>
                <v-row dense v-else>
                    <v-col cols="12">
                        <v-alert class="mt-2 mb-0" border="left" color="info" text dense>
                            {{$t('noDataAvailable')}}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-dialog persistent width="400" v-model="showDownload">
                    <v-card>
                        <v-card-text class="pa-6">
                            <h5 class="icon-title">
                            {{$t('downloadReady')}}
                            <v-btn @click="showDownload = false" text class="square">
                                <v-icon small>$close</v-icon>
                            </v-btn>
                            </h5>
                            <div class="text-center mt-4 mb-2">
                            <a :href="downloadUrl" @click="" download class="exportBtn">{{$t('clickForDownload')}}</a>
                            </div>
                            
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <programAndDissociateRPDialog v-if="clickedRP && linkedRP" :linkedRPDialog="linkedRPDialog" :clickedRP="clickedRP" :linkedRP="linkedRP" @close="closeLinkRPDialog" />
                <ConfirmDialogComponent ref="confirmDialog" />
                <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />

                <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="libType" :libParams="{ 'operation_id': operationId, 'rpSubType': rpSubType }" @cancel="formLib = null;" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" />
                </div>
        </v-card-text>
    </v-card>
    
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation';
import GenericDataService from '@/services/GenericDataService';
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import ConfirmDialogComponent from '@/components/dialogs/ConfirmDialogComponent';
import getForm from "@/mixins/mixins";
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";
import programAndDissociateRPDialog from '@/components/programAndDissociateRPDialog';


export default {
    name: "retroplanningDahsboardComponent",
    mixins: [dateManipulation, getForm],
    components:{ ConfirmDialogComponent, FormDialogComponent, LibraryDialogComponent, programAndDissociateRPDialog},
    props: ["retroplanning", "operationId", "operation"],
    data() {
        return {
            actions: [],
            form: null,
            publicPath: process.env.BASE_URL,
            api :this.$store.state.auth.apiUrl,
            formLib: null,
            libType: "",
            rpSubType: "",
            showLibraryDialog: false,
            downloadUrl: "",
            showDownload: false,
            linkedRP: [],
            clickedRP: null,
            linkedRPDialog: false
        }
    },
    created() {
        GenericDataService.getData("/operation/getRetroplanningTypes").then((response) => {
            this.actions = response.data.data;
        });
    },
    watch: {
        retroplanning(newRetroplanning) {
            if(this.retroplanning && this.retroplanning.length > 0) {
                this.retroplanning.forEach(function(step, index) {
                    if(step.status && step.status === 'DRAFT' && step.config && step.config.date_start && step.config.prestataire && step.config.prestataire.deadline_mission) {
                        let currentDate = new Date();
                        let dateLimitProvider = new Date(step.config.date_start);
                        dateLimitProvider.setDate(dateLimitProvider.getDate() - step.config.prestataire.deadline_mission);

                        if(currentDate > dateLimitProvider) {
                            step.config.prestataire.showLimitDateProvider = this.DaysBetween(dateLimitProvider, currentDate);
                        }
                    }
                    
                    if(step.config.multipleStatus){
                        const statusByEntity = {};
                        step.config.multipleStatus.forEach(element => {
                            if(!statusByEntity[""+element.status])
                                statusByEntity[""+element.status] = []

                            statusByEntity[""+element.status].push(element.entity);
                        });
                        this.retroplanning[index].config.statusByEntity = statusByEntity
                        //step.config.statusByEntity = status;
                    }
                }, this);
            }
        },
    },
    computed: {
        
    },
    methods: {
        closeLinkRPDialog(){
            this.customRefresh()
            this.linkedRPDialog = false
        },
        openForm(type, subType) {
            let canal = subType.replace('camp', '').replace('out', '').replace('conversational', '');
            canal = canal === 'CALL' ? 'TEL' : canal;

            if(['CAMP_OUT_P', 'CAMP_CALL'].includes(type)) {
                let url = "/library/getFilters?libType=" + canal + '&params[operation_id]=' + this.operationId;

                if(subType === "campSMSconversational")
                    url += '&filters[filters.type]=' + encodeURIComponent(JSON.stringify(["CONVERSATIONAL"]));

                GenericDataService.getData(url).then((response) => {
                    this.formLib = response.data.data;
                    this.showLibraryDialog = true;
                    this.libType = canal;
                    this.rpSubType = subType;
                });
            } else {
                this.getForm('/operation/' + this.operationId + '/getFormRealRetroplanning?subType=' + subType);
            }
        },
        customRefresh() {
            this.$emit('refreshRetroplanning');
        },
        // recupere l'action a faire selon le type du link (single et globaux)
        getLink(action, item) {
            action = JSON.parse(JSON.stringify(action).replaceAll('{{id}}', item.id));
            if(this.operationId)
                action = JSON.parse(JSON.stringify(action).replaceAll('{{operation_id}}', this.operationId));
            if(action.action == "get"){
                this.$router.push({ path: action.route });
            } else if(action.action == "getLink") {
                action.params.id = item.id;
                action.params.operation_id = this.operationId;  
                this.$router.push(action);
            } else if(action.action == "nameRoute") {
                let params = {};
                params[action.idName ? action.idName : 'id'] = item.id;
                this.$router.push({ name: action.nameRoute, params: params });
            } else if(action.action == "confirm") {
                this.confirmElement(action, [item.id]);
            } else if(action.action == "emit") {
                this.$emit(action.emitAction, action, item)
            } else if(action.action == "form") {
                this.getForm(action.url, [item.id], action)
            }
        },
        canGoTo(retro) {
            if(retro.actions && retro.actions.actions && retro.actions.actions['SHOW']) {
                return 'click';
            }
            return null;
        },
        confirmElement(link, arrayIds) {
            if(link.confirm.checkurl){
                let linkToCheck = link.confirm.checkurl
                GenericDataService.postData(linkToCheck, arrayIds).then((response) => {
                    let checkData = response.data.data;
                    if(checkData.hasOwnProperty('linked')){
                        if(checkData.linked) {
                            this.linkedRP = checkData.missions
                            this.clickedRP = checkData.missionsClicked
                            this.linkedRPDialog =true
                        } else {
                            this.clickedRP = checkData.missionsClicked
                            this.linkedRP = [];
                            this.linkedRPDialog =true
                        }
                    } else {
                        let options = {
                            needDoubleValidation: checkData.needDoubleValidation,
                            confirmationImpacts: checkData.impacts,
                            confirmationImpactTitle: this.$t(checkData.text),
                            action: link.url,
                            ids: arrayIds
                        }
                        this.openConfirmModal(link, options)
                    }
                })
            } else {
                let options = {
                    action: link.url,
                    ids: arrayIds
                }
                this.openConfirmModal(link, options)
            }
        },
        async openConfirmModal(link, options){
            if(await this.$refs.confirmDialog.open(this.$t(link.confirm.label), this.$t(link.confirm.message), options)) {
                this.customRefresh();
                this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => {
                    this.$store.dispatch("REMOVE_SNACK")
                });
            }
        },
        getExportUrl(retroId) {
            this.$emit('launchLoader', true);
            let url = "";
            if(this.$store.state.auth.user.is_presta)
                url = this.api + '/mission/export?id=' + retroId + '&token=' + this.$store.state.auth.idToken;
            else
                url = this.api + '/retroplanning/' + retroId + '/send?export=true&trymode=false&token=' + this.$store.state.auth.idToken+'&account_id='+this.$store.state.auth.currentAccountId;

                GenericDataService.postData(url).then((response) => {
                    if(response.data.data.directDownload){
                        this.downloadUrl = this.api + response.data.data.directDownload;
                        this.showDownload = true;
                        this.$emit('launchLoader', false);
                    }
                }).catch((e) => {
                    this.$emit('launchLoader', false);
                })
            
        }
    }
}
</script>
<style lang="scss">

    .retroplanning {

        .popover {
            background-color: #ffffff;
            padding: 10px;
            color: var(--v-warning-base);
            font-weight: 800;
        }

        .rpCard {
            //border-left: 8px solid var(--v-pink-base) !important;
            padding: 15px 15px;
            display: flex;
            align-items: center;
            border:1px solid #CCC;
            .rpNumber {
                width: 120px;
                text-align: center;
                font-weight: 700;
                color: var(--v-lightgrey-base);
                font-size: 80px;
                letter-spacing: -5px;
            }

            .rpIcon {
                border-width: 3px;
            }

            .rpName {
                font-weight: 800;
                color: #000000;
                font-size: 15px;
            }
            &:hover {
                background-color: rgba(0,0,0,0.05) !important;
            }
        }

        .retroItem {
            .retroContent {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .retroTitle {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .operation {
            color: white !important;
            background-color: var(--v-primary-base) !important;
            padding: 11px 10px;
            display: flex;
            align-items: center;

            .rpName {
                font-weight: 800;
                color: #FFFFFF;
                font-size: 18px;
                padding-bottom: 5px;
            }
        }

        .cursor-pointer {
            cursor: pointer;
        }
    }
</style>
