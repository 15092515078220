<template>
    <div>
        <v-container fluid class="pa-6">
            <v-row>
                <v-col cols="12" v-if="packId == 1">
                    STAT PACK DECOUVERTE
                </v-col>
                <v-col cols="12" v-if="packId == 2">
                    <v-row>
                        <template v-for='(stat, index) in statPackMarketing'>
                            <v-col :cols="12 / statPackMarketing.length" :key="'stat_'+index" v-if="stat.label != 'ROI'">
                                <v-card rounded="lg" class="h-100 d-flex align-center flex-column justify-center" :class="stat.label == 'ROI' ? 'bg-orange-reverse' : ''">
                                    <v-card-text style="min-height:200px; width:100%;" class="text-center align-center d-flex flex-column justify-center">
                                        <v-avatar  size="54" :color="stat.color">
                                            <v-icon size="26" color="white">{{ stat.icon }}</v-icon>
                                        </v-avatar>
                                        <h2 class="mb-1 mt-1 justify-center darkgrey--text" style="font-size:32px; width:100%;" v-if="stat.label != 'ROI'">{{ stat.value }}</h2>
                                        <span v-if="stat.label != 'ROI'" class="grey--text">{{ $t(stat.label) }}</span>
                                        <h2 class="mt-2 mb-0 white--text" v-else>{{ $t('calculateROI') }}</h2>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col v-else :cols="12 / statPackMarketing.length" :key="'stat_'+index">
                                <v-menu offset-y :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-card rounded="lg" class="h-100 d-flex align-center flex-column justify-center bg-orange-reverse" v-bind="attrs" v-on="on">
                                            <v-card-text class="text-center align-center d-flex flex-column justify-center">
                                                <v-avatar size="54" style="background-color:rgba(255,255,255,0.4)">
                                                    <v-icon size="26" color="white">{{ stat.icon }}</v-icon>
                                                </v-avatar>
                                                <h2 class="mt-2 mb-0 white--text" style="text-transform:unset">{{ $t('calculateROI') }}</h2>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                    <v-card>
                                        <v-card-title style="font-size:18px;" class="pink--text">{{ $t('calculateROI') }}</v-card-title>
                                        <v-card-text>
                                            <p>{{ $t('iinvested')+" "+stat.cost+"€"+" "+$t('andhad')+" "+stat.submission+" "+$t('submission') }}</p>
                                            <p class="input-form">
                                                <label for="">{{ $t('addmymedianbasket') }}</label>
                                                <v-text-field dense hide-details type="number" v-model="basket" solo flat></v-text-field>
                                            </p>
                                            <p class="input-form">
                                                <label for="">{{ $t('addmytransfo') }}</label>
                                                <v-text-field solo flat dense hide-details type="number" v-model="transfo"></v-text-field>
                                            </p>
                                            <div class="input-form">
                                                <label for=""><b>{{ $t('myROIis') }}</b></label>
                                                <div class="d-flex input-form align-center">
                                                    <v-text-field solo flat dense hide-details readonly type="number" class="mr-2" v-model="roi"></v-text-field>%
                                                </div>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
                <v-col cols="12" v-if="!packId || packId == 3|| packId == 4">
                    <dashboardStatComponent :dashboardStatKey="dashboardStatKey" :operationId="operationId" />
                    <!-- <NumberStatComponent :values="stat" @openStats="openStats" /> -->
                </v-col>
                <v-col  lg="4" md="6" sm='12' xs="12" order-sm="2" order-xs="2" order-md="1" v-if="!packId || packId != 1">
                    <!-- <configurationComponent :operation="operation" @refreshOperation="refreshOperation" /> -->
                    <!-- <scheduleDashboardComponent :entityId="entityId" :hours="hours" :operationId="operationId" :weekdays="operation.type === 'MARKETING'" /> -->
                    <workflowOperationComponent :operationId="operationId"/>
                    <entryPointsComponent v-if="!packId || packId == 3 || packId == 4" class="mt-6" :entrypoints="entryPoints" :entryPointsStats="entryPointsStats" :operationId="operationId" :operation="operation" @refreshEntryPoints="refreshEntryPoints" />
                    <placesOperationComponent class="mt-6" :values="places" v-if="!packId || packId == 3" :operationId="operationId"/>
                </v-col>
                <v-col  :lg="packId == 1 ? '12' : '8'" :md="packId == 1 ? '12' : '6'" sm='12' xs="12" order-sm="1" order-xs="1" order-md="2">
                    <!-- <div v-if="packId == 2" class="mb-6">
                        <v-row>
                            <v-col cols="4" v-for="(stat, index) in statPackMarketing" :key="'stat_'+index">
                                <v-card rounded="lg">
                                    <v-card-text>
                                        <div class="d-flex flex-column align-center">
                                            <v-avatar :color="stat.color" class="mb-3">
                                                <v-icon size="22" color="white">{{ stat.icon }}</v-icon>
                                            </v-avatar>
                                            
                                            <b class="black--text mb-2" style="font-size:24px;">{{ stat.count }}</b>
                                            <h5 class="text-center">{{$t(index)}}</h5>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        
                    </div> -->
                    <retroplanningDashboardComponent @launchLoader="launchLoader" class="" v-if="retroplanning && packId != 4" :operation="operation" :retroplanning="retroplanning" :operationId="operationId" @refreshRetroplanning="refreshRetroplanning" @openHistoryDrawer="openHistoryDrawer" @openReaffectDialog="openReaffectDialog" @programRp="openProgramRpDialog"/>
                </v-col>
            </v-row>
            <dialogStatsContactComponent v-if="openDialogStats" @closeStatsContactDialog="openDialogStats = false" :openDialogStats="openDialogStats" :prefilter="prefilter" :id="this.$route.params.operation_id" :idType="'operation_id'" />
            <dialogProgramRpComponent v-if="campaign.id" :openProgramRpDialog="programRpDialog" :retroplanning="campaign" @refresh="refreshRpAfterProgram" @close="programRpDialog = false"/>
            <Loader style="height:calc(100vh + 100px) !important;" v-if="openLoader"/>
        </v-container>
    </div>
</template>
<script>
import dialogStatsContactComponent from '@/components/dialogs/dialogStatsContactComponent'
import configurationComponent from '@/components/dashboardComponent/configurationComponent'
import NumberStatComponent from '@/components/dashboardComponent/NumberStatComponent'
import scheduleDashboardComponent from '@/components/dashboardComponent/scheduleDashboardComponent'
import placesOperationComponent from '@/components/dashboardComponent/placesOperationComponent'
import retroplanningDashboardComponent from '@/components/dashboardComponent/retroplanningDashboardComponent'
import entryPointsComponent from '@/components/dashboardComponent/entryPointsComponent'
import GenericDataService from '@/services/GenericDataService'
import dialogProgramRpComponent from '@/components/dialogs/dialogProgramRpComponent.vue';
import workflowOperationComponent from '../operationsComponents/workflowOperationComponent.vue'
import dashboardStatComponent from '../operationsComponents/dashboardStatComponent.vue'
import Loader from '@/components/Loader.vue'
export default {
    name:"OperationDetailDashboard",
    components: {Loader, NumberStatComponent,workflowOperationComponent, configurationComponent, scheduleDashboardComponent, placesOperationComponent, retroplanningDashboardComponent, entryPointsComponent, dialogStatsContactComponent, dialogProgramRpComponent, dashboardStatComponent},
    data() {
        return {
            entityId:1,
            operationId:0,
            retroplanning: [],
            places: [],
            hours: [],
            stat: {
                title: "fewNumbersTitle",
                data: []
            },
            statPackMarketing: [],
            entryPoints:[],
            entryPointsStats:[],
            operation:[],
            openDialogStats:false,
            prefilter: false, 
            openLoader: false,
            basket: 0,
            transfo: 0,
            invested: 0,
            submissions: 0,
            dashboardStatKey: 0,
        }
    },
    computed: {
        roi(){
            if(this.basket && this.transfo && this.invested && this.submissions){
                return  Math.floor(((((this.submissions * this.basket) * (this.transfo / 100)) - this.invested) / this.invested) * 100)
            } else {
                return 0
            }
        },
        packId(){
            if(this.$store.state.auth.currentUser.accountParameters.packId)
                return this.$store.state.auth.currentUser.accountParameters.packId
            else 
                return false
        },
        campaign(){
            return this.$store.state.campaign.campaign
        },
        programRpDialog: {
            get: function(){
                return this.$store.state.campaign.programRpDialog;
            },
            set: function(val){
                this.$store.dispatch("campaign/SET_PROGRAM_RP_DIALOG", false);
                this.$store.commit("campaign/setCampaignData", {});
            }
        },
    },
    created(){
        this.operationId = this.$route.params.operation_id
        GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/get').then((response) => {
            this.operation = response.data.data
            this.entryPoints = response.data.data.entrypoints
            this.hours = response.data.data.hours
            this.places = response.data.data.places
        });
        
        if(this.packId && this.packId == 2){
            GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/getStatsPackMarketing').then((response) => {
                this.statPackMarketing = response.data.data;
                let statRoi = this.statPackMarketing.find(stat => stat.label == 'ROI');
                this.invested = statRoi.cost;
                this.submissions = statRoi.submission;
            });
        } else {
            GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/getStats').then((response) => {
                this.stat.data = response.data.data;
            });
        }
        GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/getEntryPointsArrayStats').then((response) => {
            this.entryPointsStats = response.data.data;
        });
        if (this.packId != 4) {
            this.refreshRetroplanning();
        }
    },
    methods: {
        launchLoader(value) {
            this.openLoader = value;
        },
        openStats(item){
            this.prefilter = item.prefilter ? item.prefilter : item.label;
            this.openDialogStats = true;
        },
        refreshRetroplanning() {
            GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/getRealRetroplanning').then((response) => {
                this.retroplanning = response.data.data;
                this.dashboardStatKey++;
            });
        },
        refreshEntryPoints() {
            GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/get').then((response) => {
                this.operation = response.data.data
                this.entryPoints = response.data.data.entrypoints
                this.hours = response.data.data.hours
                this.places = response.data.data.places
            });
            GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/getEntryPointsArrayStats').then((response) => {
                this.entryPointsStats = response.data.data;
            });
        },
        refreshOperation() {
            GenericDataService.getData('/operation/' + this.$route.params.operation_id + '/get').then((response) => {
                this.operation = response.data.data;
                this.$store.dispatch('operation/SET_OPE_DATA', this.operation)
                GenericDataService.getData('/menu/getsubmenu?module=operation&id='+this.$route.params.operation_id).then((response) => {
                    let submenu = response.data.data;
                    this.$store.dispatch('base/SET_SUB_MENU', submenu)

                })
            });
        },
        openHistoryDrawer(data) {
            this.$store.dispatch("history/SET_OPEN_HISTORY_DRAWER", !this.$store.state.history.openHistoryDrawer);
            this.$store.dispatch("history/SET_API_URL", data.apiUrl);
        },
        openReaffectDialog(data){
            this.$store.dispatch("campaign/SET_REAFFECT_VENDOR", data.objectId);
        },
        openProgramRpDialog(action, campaign) {
            this.$store.commit("campaign/setCampaignData", campaign);
            this.$store.dispatch("campaign/SET_PROGRAM_RP_DIALOG", true);
        },
        refreshRpAfterProgram() {
            this.$store.dispatch("campaign/SET_PROGRAM_RP_DIALOG", false);
            this.$store.commit("campaign/setCampaignData", {});
            this.refreshRetroplanning();
        }
    },
}
</script>
<style lang="scss" scoped>



</style>
