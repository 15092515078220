<template>
    <v-dialog persistent width="600" v-model="linkedRPDialog">
        <v-card>
            <v-toolbar tile flat dark class="bg-gradient">
                <v-btn x-large depressed style="position:absolute;" color="transparent" class="ml-2" dark @click.native="$emit('close')" ><v-icon small class="mr-2">$prev</v-icon> {{ $t("back") }}</v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>{{$t('programmation')}}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text  class="pt-4">
                <div class="black--text"><b>{{ $t('missionToProgram') }}</b></div>
                <v-card outlined class="mt-2 mb-2" style="border-color:#161459 !important;" rounded="lg">
                    <v-card-title class="d-flex justify-space-between py-3">
                        <div>
                            <div class="dateElement mb-1" v-if="clickedRP && clickedRP.config.date_start">
                                {{parseFullDateToFr(clickedRP.config.date_start)}}
                                <span v-if="clickedRP.config.date_end"> - {{parseFullDateToFr(clickedRP.config.date_end)}}</span>
                            </div>
                            <h5 class="pb-0" v-if="clickedRP">{{ clickedRP.config.name }}</h5>
                        </div>
                        <div v-if="clickedRP && clickedRP.config.hasOwnProperty('coach_selected')">
                            <v-tooltip v-for="(coach, index) in clickedRP.config.coach_selected" :key="'coach_'+coach.value+'_'+clickedRP.id" top>
                                <template v-slot:activator="{ on, attrs }" class="ml-2">
                                    <v-avatar size="50" v-bind="attrs" v-on="on">
                                        <v-img :src="api+'/user/'+coach.value+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true'"></v-img>
                                    </v-avatar>
                                </template>
                                <span>{{ coach.text }}</span>
                            </v-tooltip>
                        </div>
                        <div v-else-if="clickedRP && clickedRP.config.hasOwnProperty('coach_selection')">
                            <v-tooltip v-for="(coach, index) in clickedRP.config.coach_selection" :key="'coach_'+coach.value+'_'+clickedRP.id" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-avatar size="50" v-bind="attrs" v-on="on" class="ml-2">
                                        <v-img :src="api+'/user/'+coach.value+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true'"></v-img>
                                    </v-avatar>
                                </template>
                                <span>{{ coach.text }}</span>
                            </v-tooltip>
                        </div>
                        <div v-else-if="linkedRP && linkedRP[0] && linkedRP[0].config.hasOwnProperty('coach_selection')">
                            <v-tooltip v-for="(coach, index) in linkedRP[0].config.coach_selection" :key="'coach_'+coach.value+'_'+linkedRP[0].id" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-avatar size="50" v-bind="attrs" v-on="on" class="ml-2">
                                        <v-img :src="api+'/user/'+coach.value+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true'"></v-img>
                                    </v-avatar>
                                </template>
                                <span>{{ coach.text }}</span>
                            </v-tooltip>
                        </div>
                        <div v-else-if="linkedRP && linkedRP[0] && linkedRP[0].config.hasOwnProperty('coach_selected')">
                            <v-tooltip v-for="(coach, index) in linkedRP[0].config.coach_selection" :key="'coach_'+coach.value+'_'+linkedRP[0].id" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-avatar size="50" v-bind="attrs" v-on="on" class="ml-2">
                                        <v-img :src="api+'/user/'+coach.value+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true'"></v-img>
                                    </v-avatar>
                                </template>
                                <span>{{ coach.text }}</span>
                            </v-tooltip>
                        </div>
                    </v-card-title>
                
                </v-card>
                <div class="mt-4 black--text" v-if="linkedRP.length > 0"><b>{{ $t('missionLinkedthatwillbeprogramtoo') }}</b></div>
                <v-card outlined v-for="(rp, index) in linkedRP" :key="rp.id" class="mb-2 mt-2" style="border-color:#161459 !important;" rounded="lg">
                    <v-card-title class="d-flex justify-space-between py-3">
                        <div>
                            <div class="dateElement mb-1" v-if="rp.config.date_start">
                                {{parseFullDateToFr(rp.config.date_start)}}
                                <span v-if="rp.config.date_end"> - {{parseFullDateToFr(rp.config.date_end)}}</span>
                            </div>
                            <h5 class="pb-0">{{ rp.config.name }}</h5>
                        </div>
                        <div v-if="rp.config.hasOwnProperty('coach_selected')">
                            <v-tooltip v-for="(coach, index) in rp.config.coach_selected" :key="'coach_'+coach.value+'_'+rp.id" top>
                                <template v-slot:activator="{ on, attrs }" class="ml-2">
                                    <v-avatar size="50" v-bind="attrs" v-on="on">
                                        <v-img :src="api+'/user/'+coach.value+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true'"></v-img>
                                    </v-avatar>
                                </template>
                                <span>{{ coach.text }}</span>
                            </v-tooltip>
                        </div>
                        <div v-else-if="rp.config.hasOwnProperty('coach_selection')">
                            <v-tooltip v-for="(coach, index) in rp.config.coach_selection" :key="'coach_'+coach.value+'_'+rp.id" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-avatar size="50" v-bind="attrs" v-on="on" class="ml-2">
                                        <v-img :src="api+'/user/'+coach.value+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true'"></v-img>
                                    </v-avatar>
                                </template>
                                <span>{{ coach.text }}</span>
                            </v-tooltip>
                        </div>
                        <div v-else-if="clickedRP.config.hasOwnProperty('coach_selection')">
                            <v-tooltip v-for="(coach, index) in clickedRP.config.coach_selection" :key="'coach_'+coach.value+'_'+rp.id" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-avatar size="50" v-bind="attrs" v-on="on" class="ml-2">
                                        <v-img :src="api+'/user/'+coach.value+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true'"></v-img>
                                    </v-avatar>
                                </template>
                                <span>{{ coach.text }}</span>
                            </v-tooltip>
                        </div>
                        <div v-else-if="clickedRP.config.hasOwnProperty('coach_selected')">
                            <v-tooltip v-for="(coach, index) in clickedRP.config.coach_selection" :key="'coach_'+coach.value+'_'+rp.id" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-avatar size="50" v-bind="attrs" v-on="on" class="ml-2">
                                        <v-img :src="api+'/user/'+coach.value+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true'"></v-img>
                                    </v-avatar>
                                </template>
                                <span depressed>{{ coach.text }}</span>
                            </v-tooltip>
                        </div>
                    </v-card-title>
                </v-card>
                <div class="text-right mt-5">
                    <v-btn depressed dark color="grey" @click="$emit('close')" class="mr-2">{{ $t('cancel') }}</v-btn>
                    <v-btn depressed dark color="pink" v-if="linkedRP.length > 0" @click="dissociateAndProgram" class="mr-2">{{ $t('dissociateAndProgram') }}</v-btn>
                    <v-btn depressed dark color="primary" @click="programAll">{{ $t('continue') }}</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation';
import GenericDataService from '@/services/GenericDataService';

export default {
    name: "programAndDissociateRPDialog",
    props: ["linkedRPDialog", "clickedRP", "linkedRP"],
    mixins: [dateManipulation],
    data() {
        return {
            api :this.$store.state.auth.apiUrl,
        }
    },
    methods: {
        dissociateAndProgram(){
            let payload = {
                rp: this.clickedRP,
                linkedRP: this.linkedRP
            }
            GenericDataService.postData("/mission/dissociateAndProgram", payload).then((response) => {
                this.$emit('close');
            }).catch((error) => {
                console.log(error);
            })
        },
        programAll(){
            let rps = this._.cloneDeep(this.linkedRP);
            rps.push(this._.cloneDeep(this.clickedRP));
            let payload = {
                rp: rps
            }
            GenericDataService.postData("/mission/programAll", payload).then((response) => {
                this.$emit('close');
            }).catch((error) => {
                console.log(error);
            })
        }
    },
}
</script>